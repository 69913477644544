// TODO: this should use css from @emtion/react. There's a config issue in
// storybook that is preventing styles from being applied. Revisit styling
// once storybook supports using css from emotion.
import styled from "@emotion/styled"
import moment from "moment"

import { ChevronLeftIconLight, ChevronRightIconLight } from "@ninjaone/icons"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

const StyledNavButton = styled.button`
  height: ${({ usesDropdownNavigation }) => (usesDropdownNavigation ? "32px" : "24px")};
  width: 24px;
  margin: 0;
  padding: 0;
  border: 0;
  color: ${({ theme }) => theme.colorTextStrong};
  background: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    border-radius: 4px;
  }

  &[disabled] {
    color: ${({ theme }) => theme.colorTextDisabled};
    cursor: auto;
  }
`
const currentYear = new Date().getFullYear()

const checkPrevMonthDisabled = ({ disabledDays, month, yearMinOffset }) => {
  if (disabledDays?.before) return moment(month).isSameOrBefore(disabledDays.before, "month")
  const minYearDate = new Date(yearMinOffset ? currentYear - yearMinOffset : 1970, 0, 1)
  return moment(month).isSameOrBefore(minYearDate, "month")
}

const checkNextMonthDisabled = ({ disabledDays, month, yearMaxOffset }) => {
  if (disabledDays?.after) return moment(month).isSameOrAfter(disabledDays.after, "month")
  if (yearMaxOffset) {
    const maxYearDate = new Date(currentYear + yearMaxOffset, 11, 1)
    return moment(month).isSameOrAfter(maxYearDate, "month")
  }
  return false
}

export const Navbar = ({
  captionElement,
  className,
  disabledDays,
  month,
  numberOfMonths,
  onPreviousClick,
  onNextClick,
  setMonth,
  showNextButton,
  showPreviousButton,
  yearMinOffset,
  yearMaxOffset,
}) => {
  const prevMonthDisabled = checkPrevMonthDisabled({ disabledDays, month, yearMinOffset })
  const nextMonthDisabled = checkNextMonthDisabled({ disabledDays, month, yearMaxOffset })
  const usesDropdownNavigation = !!captionElement

  const handleClick = ({ type }) => {
    const newMonth = new Date(month)
    newMonth.setMonth(type === "prev" ? month.getMonth() - 1 : month.getMonth() + 1)
    if (setMonth) {
      setMonth(newMonth)
    } else {
      // onPrevClick and onNextClick were removed from the main DatePicker implementation,
      // due to both functions breaking when the DatePicker is wrapped in a <form>.
      // When the calendar is used in isolation (ex: filters) the functions should be
      // restored to update the month directly from the package.
      type === "prev" ? onPreviousClick() : onNextClick()
    }
  }

  return (
    <div className={`${className} ${className}--${numberOfMonths > 1 ? "multi" : "single"}Month`}>
      <StyledNavButton
        aria-label={localized("Previous month")}
        data-ninja-date-picker-prev
        disabled={prevMonthDisabled || !showPreviousButton}
        onClick={() => handleClick({ type: "prev" })}
        type="button"
        {...{ usesDropdownNavigation }}
      >
        <ChevronLeftIconLight />
      </StyledNavButton>
      <StyledNavButton
        aria-label={localized("Next month")}
        data-ninja-date-picker-next
        disabled={nextMonthDisabled || !showNextButton}
        onClick={() => handleClick({ type: "next" })}
        type="button"
        {...{ usesDropdownNavigation }}
      >
        <ChevronRightIconLight />
      </StyledNavButton>
    </div>
  )
}
